import {useForm} from "react-hook-form";
import './App.css';
import {useEffect, useState} from "react";
import Headers from "./Header";
import Posts from "./Posts";

function App() {

    const [data, setData] = useState([])

    const {register, handleSubmit} = useForm();
    const onSubmit = (formData) => {
        let allPosts = [...data, formData]
        fetch('https://posts-app.avs2170.workers.dev/',
            {
                method: 'post',
                body: JSON.stringify({"posts": allPosts}),
            })
            .then(response => setData(allPosts))
            .catch((error) => {
                console.error('Error:', error);
                alert("Error :" + error)
            });
    }
    useEffect(() => {
        fetch('https://posts-app.avs2170.workers.dev/')
            .then(response => response.json())
            .then(data => setData(data['posts']));
    }, [])

    return (
        <div>
            <div className="row" style={{textAlign: "center"}}>
                <div className={"col"}>
                    <Headers/>
                </div>
            </div>

            <div className="row">
                <div className={"col-1"}></div>
                <div className={"col-3"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input {...register("username")} placeholder="User Name"/>
                        <input {...register("title")} placeholder="Title"/>

                        <textarea rows={5} cols={56} {...register("content")} placeholder="Content"
                                  style={{borderRadius: 5, padding: 10}}/>
                        <input type="submit"/>
                    </form>
                </div>
                <div className={"col-7 scroll"}>

                    {data.length > 0 ?
                        data.map((posts) => (
                            <Posts user={posts.username} content={posts.content} title={posts.title}/>
                        ))
                        :
                        <h6> Please Post some Content To View Here </h6>
                    }
                </div>
                <div className={"col-1"}></div>
            </div>
        </div>
    );
}

export default App;
